import React, { Component } from "react";
import moment from "moment";
import * as yup from "yup";
import EventBus from "eventing-bus";
import { ACTION_TYPES, bankCodes } from "src/constants";
import { Form, Formik } from "formik";
import Select from "react-select";
import { Convert } from "src/helpers";
import { Dialog, DialogContent, DialogTitle, Grid, Checkbox, Switch, Box,
  TextField, Autocomplete, CheckBoxOutlineBlankIcon, CheckBoxIcon, FormControl, FormLabel, FormGroup,
  FormControlLabel
} from '@mui/material'
import {
  TextInputOne,
  InputSelectOne,
  ButtonOne,
} from "src/components/form-inputs";
import { toast } from "react-toastify";
import styles from "./_create-generic-link.module.scss";
import cx from "classnames";
import { paymentMethods, mandateAuthenticationMethods } from "src/constants";
import _ from "lodash";

const initialFund = [
  { fundList: [], selectedFund: { label: "", value: "" }, selectedAmc: "" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CreateGenericLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amcs: [],
      isModalOpen: false,
      isAmcLoading: false,
      isFundsLoading: false,
      isCreating: false,
      funds: {},
      fundData: [...initialFund],
      isSubmit: false,
      selectedIndex: 0,
      selectedPaymentMethods: _.map(paymentMethods, 'value'),
      selectedMandateAuthenticationMethods: _.map(mandateAuthenticationMethods, 'value'),
      mandateAmount: null,
      sipAmount: null,
      lumpsumAmount: null,
      selectedLandingPage: null,
      isLandingPageLoading: false,
      defaultBrokerCode: props.defaultArnNumber,
      defaultEuin: props.defaultEuin,
      defaultEmployeeCode: props.defaultEmployeeCode,
      searchTerm: "",
      bankCodeSearchTerm: "",
      selectedBankCodes: [],
      isEnabledRegularDirectSwitch: true
    };
  }

  validationSchema = yup.object().shape({
    nickname: yup.string().required("Please enter a name for this link."),
  });

  componentDidMount() {
    this.amcSubscription = EventBus.on(ACTION_TYPES.REQUEST_ADMIN_AMCS, () => {
      this.setState({ isAmcLoading: true });
      this.formik.setFieldValue("fund", "");
    });

    this.amcSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_AMCS_SUCCESS,
      (data) => {
        if (data && data.length === 1) {
          this.props.requestAdminFunds({ amc_code: data[0].code });
        }
        this.setState({ isAmcLoading: false, amcs: data });
      }
    );

    this.amcFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_AMCS_FAILED,
      () => this.setState({ isAmcLoading: false })
    );

    this.fundSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_FUNDS,
      () => {
        this.setState({ isFundsLoading: true });
      }
    );

    this.fundSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FUNDS_SUCCESS,
      (res) => {
        const { fundData, selectedIndex } = this.state;
        let newFundData = [...fundData];
        const getIndex = fundData.findIndex((d, i) => i === selectedIndex);
        newFundData[getIndex] = {
          ...newFundData[getIndex],
          fundList: res || [],
          selectedFunds: [],
        };

        this.setState({
          fundData: newFundData,
          selectedIndex: "",
          isFundsLoading: false,
        });
      }
    );

    this.fundFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_FUNDS_FAILED,
      () => this.setState({ isFundsLoading: false })
    );

    this.createSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_CREATE_GENERIC_LINK,
      () => {
        this.setState({ isCreating: true });
      }
    );

    this.createSuccessSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_SUCCESS,
      () =>
        this.setState(
          { isCreating: false },
          this.props.handleOnCompleteCreateGenericLink
        )
    );

    this.createFailureSubscription = EventBus.on(
      ACTION_TYPES.ADMIN_CREATE_GENERIC_LINK_FAILED,
      () => this.setState({ isCreating: false })
    );

    this.requestLandingPages = EventBus.on(
      ACTION_TYPES.REQUEST_LANDING_PAGES,
      () => this.setState({ isLandingPageLoading: true })
    );
    this.requestLandingPagesSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_LANDING_PAGES_SUCCESS,
      this.handleLandingPagesSuccess
    );
    this.requestLandingPagesFailure = EventBus.on(
      ACTION_TYPES.REQUEST_LANDING_PAGES_FAILURE,
      () => this.setState({ isLandingPageLoading: false })
    );

    this.props.requestLandingPages({ page: this.state.pageNumber });
  }

  componentWillUnmount() {
    this.amcSubscription();
    this.amcSuccessSubscription();
    this.amcFailureSubscription();

    this.fundSubscription();
    this.fundSuccessSubscription();
    this.fundFailureSubscription();

    this.createSubscription();
    this.createSuccessSubscription();
    this.createFailureSubscription();

    this.requestLandingPages();
    this.requestLandingPagesSuccess();
    this.requestLandingPagesFailure();
  }

  componentDidUpdate() {
    const { isModalOpen } = this.state;

    if (this.props.show && !isModalOpen) {
      this.setState({ isModalOpen: true }, this.props.requestAdminAmcs);
    }
  }

  handleSubmit = (formValues) => {
    const { nickname } = formValues || {};
    this.setState({ isSubmit: true }, () => {
      const { fundData, selectedPaymentMethods, selectedMandateAuthenticationMethods, selectedBankCodes } = this.state;
      const landingPageParams = this.state.selectedLandingPage
        ? { landing_page_uuid: this.state.selectedLandingPage }
        : {};
      const selectedFundsData = _.flatMap(fundData, 'selectedFunds')

      let folioFilters = null
      if(!_.isEmpty(selectedBankCodes))
        folioFilters = { bank: { codes: selectedBankCodes} }

      this.props.requestAdminCreateGenericLink({
        checkout: {
          nickname: nickname,
          default_mandate_amount: this.state.mandateAmount,
          default_sip_amount: this.state.sipAmount,
          default_lumpsum_amount: this.state.lumpsumAmount,
          default_broker_code: this.state.defaultBrokerCode,
          default_euin: this.state.defaultEuin,
          employee_code: this.state.defaultEmployeeCode,
          funds: selectedFundsData.map((d) => ({
            amc_code: d.amcCode,
            code: d.code,
          })),
          allow_regular_direct_switch: this.state.isEnabledRegularDirectSwitch,
          folio_filters: folioFilters,
          payment_modes: selectedPaymentMethods,
          mandate_authentication_methods: selectedMandateAuthenticationMethods,
          ...landingPageParams,
        },
      });
    });
  };

  handleAddFundList = () => {
    const { fundData } = this.state;
    this.setState({ fundData: [...fundData, ...initialFund] });
  };

  handleRemoveFundList = (index) => {
    const { fundData } = this.state;
    const filteredFundData = fundData.filter((d, i) => i !== index);
    this.setState({ fundData: filteredFundData });
  };

  handleAmcChange = ({ value, index }) => {
    const { fundData } = this.state;
    let copyFundData = [...fundData];
    const getIndex = fundData.findIndex((d, i) => i === index);
    copyFundData[getIndex] = {
      ...copyFundData[getIndex],
      selectedAmc: value,
    };
    this.setState({ fundData: copyFundData, selectedIndex: index }, () => {
      this.props.requestAdminFunds({ amc_code: value });
    });
  };

  handleLandingPageChange = ({ value }) => {
    this.setState({ selectedLandingPage: value });
  };

  handleCheckbox = (data) => {
    const { selectedPaymentMethods } = this.state;
    let copySelectedPaymentMethods = [...selectedPaymentMethods];
    if (copySelectedPaymentMethods.includes(data?.value)) {
      copySelectedPaymentMethods = copySelectedPaymentMethods.filter(
        (d) => d !== data?.value
      );
    } else {
      copySelectedPaymentMethods.push(data?.value);
    }
    this.setState({ selectedPaymentMethods: copySelectedPaymentMethods });
  };

  handleSelectAllCheckbox = () => {
    const { selectedPaymentMethods } = this.state;
    if (selectedPaymentMethods.length !== paymentMethods.length) {
      this.setState({
        selectedPaymentMethods: paymentMethods.map((d) => d.value),
      });
    } else {
      this.setState({
        selectedPaymentMethods: [],
      });
    }
  };

  handleSelectAllMandateAuthsCheckbox = () => {
    const { selectedMandateAuthenticationMethods } = this.state;

    if (selectedMandateAuthenticationMethods.length !== mandateAuthenticationMethods.length) {
      this.setState({
        selectedMandateAuthenticationMethods: mandateAuthenticationMethods.map((d) => d.value),
      });
    } else {
      this.setState({selectedMandateAuthenticationMethods: []});
    }
  }

  handleMandateAuthCheckbox = (data) => {
    const { selectedMandateAuthenticationMethods } = this.state;
    let copySelectedMandateAuthMethods = [...selectedMandateAuthenticationMethods];
    if (copySelectedMandateAuthMethods.includes(data?.value)) {
      copySelectedMandateAuthMethods = copySelectedMandateAuthMethods.filter(
        (d) => d !== data?.value
      );
    } else {
      copySelectedMandateAuthMethods.push(data?.value);
    }
    this.setState({ selectedMandateAuthenticationMethods: copySelectedMandateAuthMethods });
  }

  handleLandingPagesSuccess = () => {
    this.setState({ isLandingPageLoading: false });
  };

  handleChangeSearchTerm = (event) => {
    this.setState({searchTerm: event.target.value})
  }

  handleSelectedFund = (event, selectedOptions, index) => {
    const newFundData = [...this.state.fundData]
    const selectedFunds = newFundData[index]

    selectedFunds.selectedFunds = selectedOptions

    this.setState({fundData: newFundData})
  }

  handleSelectedBankCode = (event, selectedOptions) => {
    this.setState({selectedBankCodes: selectedOptions})
  }

  handleChangeBankCodeSearchTerm = (event) => {
    this.setState({bankCodeSearchTerm: event.target.value})
  }

  switchRegularDirect = () => {
    this.setState({ isEnabledRegularDirectSwitch: !this.state.isEnabledRegularDirectSwitch});
  }

  render() {
    const { show, adminAmcs, landingPages } = this.props;
    const {
      isFundsLoading,
      isAmcLoading,
      isCreating,
      fundData,
      selectedIndex,
      isLandingPageLoading,
    } = this.state;

    let isMultipleAmcs = _.size(this.state.amcs) > 1
    const fundsLoading = isFundsLoading || isAmcLoading

    return (
      <Dialog
        open={show}
        fullWidth={true}
        maxWidth="md"
        onClose={() => this.props.handleClose()}
      >
        <DialogTitle>New Generic Link</DialogTitle>
        <Formik
          initialValues={{
            nickname: "",
          }}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmit}
          innerRef={(ref) => (this.formik = ref)}
        >
          <Form>
            <div className={styles["content"]}>
              <TextInputOne
                name="nickname"
                id="nickname"
                label="Generic link name"
                type="text"
                placeholder="Moneycontrol"
                sizeType="medium"
                inputType={"onlysomecharactersallowed"}
                maxLength={60}
              />

              <div className={styles["divider"]} />

              {fundData &&
                fundData.length > 0 &&
                fundData.map((data, index) => {
                  return (
                    <div key={index}>
                      {index === fundData.length - 1 && isMultipleAmcs && (
                        <div className={styles["amc-select-box"]}>
                          <label>Select AMC</label>

                          <select
                            className={styles["select-box"]}
                            onChange={(e) =>
                              this.handleAmcChange({
                                value: e.target.value,
                                index,
                              })
                            }
                          >
                            <option value="" selected={data?.selectedAmc}>
                              {isAmcLoading ? "Loading..." : "Select AMC"}
                            </option>

                            {adminAmcs?.map((data, index) => (
                              <option
                                key={index}
                                value={data?.code}
                                selected={data?.selectedAmc}
                              >
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <Grid container spacing={1} className={styles['fund-selection-container']}>
                        <Grid item xs={isMultipleAmcs ? 11 : 12}>
                          <Autocomplete
                            multiple
                            disabled={ fundsLoading }
                            options={fundData[index]?.fundList || []}
                            disableCloseOnSelect
                            inputValue={this.state.searchTerm}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props} value={option.name}>
                                <Checkbox
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            onChange={(event, selectedOptions) => this.handleSelectedFund(event, selectedOptions, index)}
                            style={{ width: '100%', marginBottom: '12px' }}
                            renderInput={(params) => (
                              <TextField onChange={this.handleChangeSearchTerm} {...params} label={fundsLoading ? "Loading funds..." : "Choose funds (optional, leave this blank for all funds)"} placeholder="ELSS Fund" />
                            )}
                          />
                        </Grid>
                        {isMultipleAmcs &&
                          <Grid item xs={1}>
                            <div>
                              {index === fundData.length - 1 ? (
                                <div
                                  onClick={this.handleAddFundList}
                                  className={styles["add-btn"]}
                                >
                                  +
                                </div>
                              ) : (
                                <div
                                  className={styles["remove-btn"]}
                                  key={index}
                                  onClick={() =>
                                    this.handleRemoveFundList(index)
                                  }
                                >
                                  -
                                </div>
                              )}
                            </div>
                          </Grid>
                        }
                      </Grid>
                    </div>
                  );
                })}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">Lumpsum Payment Methods</FormLabel>

                    <FormControlLabel
                      label="Select all"
                      control={
                        <Checkbox
                          checked={paymentMethods?.length === this.state.selectedPaymentMethods?.length}
                          onChange={this.handleSelectAllCheckbox}
                        />
                      }
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {paymentMethods?.map((data, index) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox checked={this.state.selectedPaymentMethods.includes(data?.value)} onChange={() => this.handleCheckbox(data)} name={data?.value} />
                            }
                            label={data?.name}
                          />
                        );
                      })}
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormLabel component="legend">SIP Payment Methods</FormLabel>

                    <FormControlLabel
                      label="Select all"
                      control={
                        <Checkbox
                          checked={mandateAuthenticationMethods?.length === this.state.selectedMandateAuthenticationMethods?.length}
                          onChange={this.handleSelectAllMandateAuthsCheckbox}
                        />
                      }
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {mandateAuthenticationMethods?.map((data, index) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox checked={this.state.selectedMandateAuthenticationMethods.includes(data?.value)} onChange={() => this.handleMandateAuthCheckbox(data)} name={data?.value} />
                            }
                            label={data?.name}
                          />
                        );
                      })}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={styles["divider"]} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Default broker code (Optional)
                  </div>

                  <TextInputOne
                    name="defaultBrokerCode"
                    id="defaultBrokerCode"
                    placeholder="ARN-000000"
                    sizeType="medium"
                    value={this.state.defaultBrokerCode}
                    onChange={(e) => { this.setState({defaultBrokerCode: e.target.value}) }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Employee code (Optional)
                  </div>

                  <TextInputOne
                    name="defaultEmployeeCode"
                    id="defaultEmployeeCode"
                    placeholder="AAA-BBB"
                    sizeType="medium"
                    value={this.state.defaultEmployeeCode}
                    onChange={(e) => { this.setState({defaultEmployeeCode: e.target.value}) }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Default EUIN (Optional)
                  </div>

                  <TextInputOne
                    name="defaultEuin"
                    id="defaultEuin"
                    placeholder="AAA-BBB"
                    sizeType="medium"
                    value={this.state.defaultEuin}
                    onChange={(e) => { this.setState({defaultEuin: e.target.value}) }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Default amount for SIPs (Optional)
                  </div>

                  <TextInputOne
                    name="sipAmount"
                    id="sipAmount"
                    type="number"
                    step="1"
                    placeholder="10,000"
                    sizeType="medium"
                    value={this.state.sipAmount}
                    onChange={(e) => {
                      this.setState({
                        sipAmount: parseInt(e.target.value),
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Default lumpsum amount (Optional)
                  </div>

                  <TextInputOne
                    name="lumpsumAmount"
                    id="lumpsumAmount"
                    type="number"
                    step="1"
                    placeholder="10,000"
                    sizeType="medium"
                    value={this.state.lumpsumAmount}
                    onChange={(e) => {
                      this.setState({
                        lumpsumAmount: parseInt(e.target.value),
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Default mandate amount for SIPs (Optional)
                  </div>

                  <TextInputOne
                    name="mandateAmount"
                    id="mandateAmount"
                    type="number"
                    step="1"
                    placeholder="10,000"
                    sizeType="medium"
                    value={this.state.mandateAmount}
                    onChange={(e) => {
                      this.setState({
                        mandateAmount: parseInt(e.target.value),
                      });
                    }}
                  />
                </Grid>
              </Grid>

              <div className={styles["divider"]} />

              <Grid container spacing={2} style={{marginTop: 12}}>
                <Grid item xs={6}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Bank filters - show & allow creation of folios with bank accounts only of certain IFSCs (Optional)
                  </div>

                  <Autocomplete
                    multiple
                    options={bankCodes}
                    disableCloseOnSelect
                    inputValue={this.state.bankCodeSearchTerm}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} value={option}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    onChange={(event, selectedOptions) => this.handleSelectedBankCode(event, selectedOptions)}
                    style={{ width: '100%', marginBottom: '12px', marginTop: 10 }}
                    renderInput={(params) => (
                      <TextField onChange={this.handleChangeBankCodeSearchTerm} {...params} label={"Choose bank codes (leave this blank for all banks)"} />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Is the customer allowed to switch from regular to direct (or vice versa)?</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name="enableRegularDirectSwitch"
                            onChange={this.switchRegularDirect}
                            checked={this.state.isEnabledRegularDirectSwitch}
                          />
                        }
                        label={this.state.isEnabledRegularDirectSwitch ? "Yes" : "No"}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div className={styles["divider"]} />
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <div className={styles["label"]} style={{ marginBottom: 5 }}>
                    Choose landing page (Optional)
                  </div>

                  <div className={cx(styles["fund-amount-selectbox"])}>
                    <Select
                      defaultValue={{
                        label: "Choose a landing page",
                        value: "",
                      }}
                      className={styles["fundlable"]}
                      onChange={(e) =>
                        this.handleLandingPageChange({
                          value: e.value,
                        })
                      }
                      options={
                        _.map(landingPages, (page) => ({
                          label: page.landingPageName,
                          value: page.uuid,
                        })) || []
                      }
                    ></Select>
                  </div>
                </Grid>
              </Grid>

              <div className={styles["divider"]} />
              <div className={styles["footer"]}>
                <ButtonOne type="submit" disabled={isCreating}>
                  {isCreating ? "Loading..." : "Create Generic Link"}
                </ButtonOne>
              </div>
            </div>
          </Form>
        </Formik>
      </Dialog>
    );
  }
}

export default CreateGenericLink;
